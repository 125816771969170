<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #f2c98a"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <!--          <a href="#" class="text-center mb-10">-->
          <!--            <img-->
          <!--              src="media/logos/logo-letter-1.png"-->
          <!--              class="max-h-70px"-->
          <!--              alt=""-->
          <!--            />-->
          <!--          </a>-->
          <!--          <h3-->
          <!--            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"-->
          <!--            style="color: #986923;"-->
          <!--          >-->
          <!--            Discover Amazing Metronic <br />with great build tools-->
          <!--          </h3>-->
        </div>
        <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Welcome to 9Pay
                </h3>
              </div>
              <!--              <div class="form-group">-->
              <!--                <label class="font-size-h6 font-weight-bolder text-dark"-->
              <!--                  >Email</label-->
              <!--                >-->
              <!--                <div-->
              <!--                  id="example-input-group-1"-->
              <!--                  label=""-->
              <!--                  label-for="example-input-1"-->
              <!--                >-->
              <!--                  <input-->
              <!--                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"-->
              <!--                    type="text"-->
              <!--                    name="email"-->
              <!--                    placeholder="Nhập địa chỉ email"-->
              <!--                    ref="email"-->
              <!--                    v-model="form.email"-->
              <!--                  />-->
              <!--                </div>-->
              <!--              </div>-->
              <!--              <div class="form-group">-->
              <!--                <div class="d-flex justify-content-between mt-n5">-->
              <!--                  <label class="font-size-h6 font-weight-bolder text-dark pt-5"-->
              <!--                    >Password</label-->
              <!--                  >-->
              <!--                </div>-->
              <!--                <div-->
              <!--                  id="example-input-group-2"-->
              <!--                  label=""-->
              <!--                  label-for="example-input-2"-->
              <!--                >-->
              <!--                  <input-->
              <!--                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"-->
              <!--                    type="password"-->
              <!--                    name="password"-->
              <!--                    placeholder="Nhập mật khẩu"-->
              <!--                    ref="password"-->
              <!--                    v-model="form.password"-->
              <!--                    autocomplete="off"-->
              <!--                  />-->
              <!--                </div>-->
              <!--              </div>-->
              <div class="pb-lg-0 pb-5">
              <!--                <button-->
              <!--                  ref="kt_login_signin_submit"-->
              <!--                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"-->
              <!--                >-->
              <!--                  Sign In-->
              <!--                </button>-->
                <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                  @click="loginGoogle"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="media/svg/social-icons/google.svg"
                    /> </span
                  >Sign in with Google
                </button>
              </div>
            </form>
          </div>
          <!--end::Signin-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <!--          <a href="#" class="text-primary font-weight-bolder font-size-h5"-->
          <!--            >Terms</a-->
          <!--          >-->
          <!--          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"-->
          <!--            >Plans</a-->
          <!--          >-->
          <!--          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"-->
          <!--            >Contact Us</a-->
          <!--          >-->
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import {
  // LOGIN_GOOGLE,
  LOGIN,
  LOGOUT,
  // SET_ERROR
} from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

import Vue from "vue";
// import VueGapi from "vue-gapi";
import GAuth from "vue-google-oauth2";
const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: "email",
  prompt: "consent",
  plugin_name: "App Name that you used in google developer console API",
};

Vue.use(GAuth, gauthOption);
export default {
  name: "login-1",
  data() {
    return {
      state: "signin",
      form: {
        email: "",
        password: "",
      },
    };
  },
  created() {},
  components: {},
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-1.svg"
      );
    },
  },
  mounted() {
    const signin_form = KTUtil.getById("kt_login_signin_form");
    this.fv = formValidation(signin_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: "Username is required",
            },
          },
        },
        password: {
          validators: {
            notEmpty: {
              message: "Password is required",
            },
          },
        },
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap(),
      },
    });

    this.fv.on("core.form.valid", () => {
      var email = this.form.email;
      var password = this.form.password;
      // clear existing errors
      this.$store.dispatch(LOGOUT);
      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      setTimeout(() => {
        // send register request
        this.$store.dispatch(LOGIN, { email, password }).then(() => {
          if (this.errors) {
            Swal.fire({
              title: "",
              text: this.errors,
              icon: "error",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          }
          this.$router.push({ name: "dashboard" });
        });
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }, 1000);
    });
  },
  methods: {
    loginGoogle() {
      this.$gAuth
        .signIn()
        .then((GoogleUser) => {
          // on success do something
          var access_token = GoogleUser.getAuthResponse().access_token;
          if (!access_token) {
            this.notifyAlert("warn", "Lỗi không tìm thấy access token google");
            return;
          }
          this.$bus.$emit("show-loading", true);
          // clear existing errors
          this.$store.dispatch(LOGIN, { access_token }).then(() => {
            if (this.errors) {
              Swal.fire({
                title: "",
                text: this.errors,
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
            }
            this.$router.push({ name: "dashboard" });
            this.$bus.$emit("show-loading", false);
          });
        })
        .catch((error) => {
          alert(error);
        });
    },
  },
};
</script>
